exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-board-of-directors-tsx": () => import("./../../../src/pages/board-of-directors.tsx" /* webpackChunkName: "component---src-pages-board-of-directors-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-forms-tsx": () => import("./../../../src/pages/forms.tsx" /* webpackChunkName: "component---src-pages-forms-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-advisor-tsx": () => import("./../../../src/pages/legal-advisor.tsx" /* webpackChunkName: "component---src-pages-legal-advisor-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/Services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-terms-for-lawyers-tsx": () => import("./../../../src/pages/terms-for-lawyers.tsx" /* webpackChunkName: "component---src-pages-terms-for-lawyers-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-use-guide-tsx": () => import("./../../../src/pages/use-guide.tsx" /* webpackChunkName: "component---src-pages-use-guide-tsx" */),
  "component---src-pages-why-us-tsx": () => import("./../../../src/pages/why-us.tsx" /* webpackChunkName: "component---src-pages-why-us-tsx" */)
}

